import {getRandomInteger} from './utils.js';

const glitchParagraph = (element) => {
  const array = element.innerHTML.split('');
  const special = ['~', '@', '!', '#', '$', '%', '^', '&', '*'];
  const exception = [' ', '\n', '.', ','];

  const numArray = [];
  array.forEach(char => {
    const num = getRandomInteger(5, 40);
    numArray.push(num);
  })

  let completeCount;
  let newText;

  const timer = setInterval(() => {
    completeCount = 0
    newText = ''
    numArray.forEach((num, i) => {
      if (exception.includes(array[i]) || numArray[i] === 0) {
        newText += array[i]
        completeCount += 1
      } else {
        newText += special[numArray[i] % special.length]
        numArray[i] = --num
      }
    })

    element.innerHTML = newText
    if (completeCount === numArray.length) clearInterval(timer)
  }, 100);
}

export {glitchParagraph};
