import {getRandomInteger} from './utils.js';

const STARS = 400;
const MAX_TIME = 30;

const generateStars = (element) => {
  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  const resizeHandler = () => {
    window.removeEventListener('resize', resizeHandler, true);
    element.innerHTML = '';
    generateStars(element);
  }
  window.addEventListener('resize', resizeHandler, true);

  const createStar = () => {
    const starElement = document.createElement("div");
    starElement.classList.add("star" + getRandomInteger(0, 3));
    return starElement;
  }

  for (var i = 0; i <= STARS; i++) {
    const ypos = Math.round(Math.random() * height);
    const speed = 1000 * (Math.random() * MAX_TIME + 1);

    const star = createStar();
    element.appendChild(star);
    star.animate(
      [
        {
          transform: "translate3d(" + width + "px, " + ypos + "px, 0)"
        },
        {
          transform:
            "translate3d(-" + Math.random() * 256 + "px, " + ypos + "px, 0)"
        }
      ],
      {
        delay: Math.random() * -speed,
        duration: speed,
        iterations: 1000
      }
    );
  }
}

export {generateStars};
