import {generateStars} from './stars.js';
import {glitchParagraph} from './glitchParagraph.js';
import Typed from 'typed.js';

generateStars(document.querySelector('.space'));

const options = {
  strings: [
    "`<span class=\"type-green\">root@savinov:~#</span> `^3000 ls^2000\n" +
    "`fsociety.sh`\n" +
    "`backup_utkonos_DB.sh`\n" +
    "`hack_the_universe.sh`\n" +
    "`<span class=\"type-green\">root@savinov:~#</span> `^4000 ./hack_the_universe.sh^2000\n" +



    "`Hacking`.^1000.^1000.^1000.^1000.^1000.^1000.^1000\n\n" +
    "Wake up, Neo...^4000\n" +
    "The Matrix has you...^4000\n" +
    "Follow the white rabbit.^4000\n" +
    "Knock, knock, Neo^5000\n\n" +
    "> Kernel panic! Trying to reboot.^1000.^1000.^1000\n" +
    "> Failed! Reboot and select proper Boot Device or Insert Boot Media in selected Boot device\n"],
  typeSpeed: 40,
  showCursor: true,
  cursorChar: '_',
  autoInsertCss: true,
  onComplete: (self) => {
    glitchParagraph(document.querySelector('.typed'));
  },
};

const typed = new Typed('.typed', options);
